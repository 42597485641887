import { render, staticRenderFns } from "./Verordnung.vue?vue&type=template&id=a4cc6a3c"
import script from "./Verordnung.vue?vue&type=script&lang=js"
export * from "./Verordnung.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalDisplayFachbereichBadge: require('/builds/logo-yes/portal2022/frontend/components/global/display/FachbereichBadge.vue').default,InternVerordnungenVerordnungDetails: require('/builds/logo-yes/portal2022/frontend/components/intern/verordnungen/VerordnungDetails.vue').default,GlobalIconsCheck: require('/builds/logo-yes/portal2022/frontend/components/global/icons/check.vue').default,ModalsDetailsVerordnungDokumentation: require('/builds/logo-yes/portal2022/frontend/components/modals/details/VerordnungDokumentation.vue').default,ModalsDetailsDokumentation: require('/builds/logo-yes/portal2022/frontend/components/modals/details/Dokumentation.vue').default,ModalsDetailsDateien: require('/builds/logo-yes/portal2022/frontend/components/modals/details/Dateien.vue').default,ModalsDetailsSchreiben: require('/builds/logo-yes/portal2022/frontend/components/modals/details/Schreiben.vue').default,InternVerordnungenVerordnungStatus: require('/builds/logo-yes/portal2022/frontend/components/intern/verordnungen/VerordnungStatus.vue').default})
