
import kontakte from '~/mixins/models/kontakte';
export default {
  mixins: [kontakte],
  props: ['patient', 'kontaktnummer', 'noEvents'],
  data() {
    return {
      emptyKontaktperson: {
        kontakt: null,
        beschreibung: '',
        erziehungsberechtigt: null,
        deleted_at: null,
      },
      rufnummerUebernommen: false,
      rechnungskontaktEmpty: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    kontaktpersonHinzufuegen() {
      this.patient.patientenkontakte.push(this.$copy(this.emptyKontaktperson));
    },
    speichern() {
      let send = this.patient;
      // Leeren Rechnungskontakt ignorieren
      if (this.rechnungskontaktEmpty) send.rechnungskontakt = null;
      if (this.patient.id) {
        this.$$update('patienten', send, 'Patient aktualisiert').then(response => {
          if (!this.noEvents) {
            this.$nuxt.$emit('update-patient', response);
            this.$nuxt.$emit('update-kontakt', response.kontakt);
          }
          this.$emit('done');
        });
      }
      else {
        this.$$store('patienten', send, 'Neuer Patient gespeichert').then(response => {
          if (!this.noEvents) {
            this.$nuxt.$emit('store-patient', response);
            this.$nuxt.$emit('store-kontakt', response.kontakt);
          }
          this.$emit('done');
        });
      }
    },
    nummerUebernehmen(kontakt) {
      if (kontakt.telefonnummern) {
        kontakt.telefonnummern.push({bezeichnung: '', nummer: this.kontaktnummer});
        this.rufnummerUebernommen = true;
      }
    },
    updatePatientEinrichtungen(einrichtungen) {
      if (!einrichtungen) {
        this.patient.einrichtungen = null;
        return;
      }
      this.patient.einrichtungen = einrichtungen.map(el => {
        if (!el.pivot) {
          if (el.bewohnt) el.pivot = {wohnt_da: true};
          else el.pivot = {wohnt_da: false};
        }
        return el;
      })
    }
  },
  mounted() {},

}
