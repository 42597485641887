
import faelle from '../../../mixins/faelle';
export default {
  mixins: [faelle],
  props: ['status'],
  data() {
    return {
      page: 1,
      total: null,
    }
  },
  computed: {},
  watch: {
    status(neu) {
      this.letzterClickSetzen();
    }
  },
  methods: {
    letzterClickSetzen() {
      if (!this.patient_id) this.$store.commit('telefonie/setLetzterClick', this.status);
    },
  },
  mounted() {
    this.letzterClickSetzen();
  }

}
