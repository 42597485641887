
export default {
  mixins: [],
  props: ['verordnung', 'verordnungen'],
  data() {
    return {
      verordnungDetails: null,
      selectedModel: null,
      verordnungShow: null,
      loadingDetails: false,
    }
  },
  computed: {},
  watch: {
    verordnung() {
      this.verordnungShow = this.verordnung;
      this.therapiestundenAbrufen();
    }
  },
  methods: {
    select(type, model) {
      this.verordnungDetails = type;
      this.selectedModel = model;
      this.$emit('size', 'lg');
    },
    verordnungAbbrechen() {
      this.$nuxt.$emit('display-verordnung-abbrechen-modal', this.verordnung);
    },
    therapiestundenAbrufen() {
      this.$$abrufen('therapiestunden');
    },
    verordnungDetailsAbrufen() {
      this.$$fill('verordnungen', {filter: [{where: ['patient_id', this.patient_id]}], with: ['diagnosegruppe', 'icdcode', 'icdcode_2', 'vorderseiten'], append: ['therapiestunden_frei', 'leitsymptomatik_aufbereitet', 'rueckseite', 'vorderseite', 'behandlungsvertrag', 'behandlungsvertrag_unterschrieben']})
      this.$$find('verordnungen', this.verordnung.id).then(verordnung => {
        this.verordnungShow = verordnung;
      });
    }
  },
  mounted() {
    this.verordnungShow = this.verordnung;
    this.$$fill('therapiestunden', {filter: [{where: ['verordnung_id','=', this.verordnung.id]}]});
    this.therapiestundenAbrufen();
    this.verordnungDetailsAbrufen();
  },

}
