import Vue from 'vue';

let helpers = {
  methods: {
    $$timeago(datum) {
      let diff = this.$moment().diff(datum);
      let format = (diff > 3600000) ? 'HH:mm:ss' : 'mm:ss'
      return this.$moment(diff).subtract(1, 'hour').format(format);
    },
    $dauerAnruf(duration) {
      let mom = this.$moment.utc(duration*1000);
      if (mom.format('H') > 0) return mom.format('H:mm:ss');
      return mom.format('mm:ss');
    },
    $round(number, digits = 2) {
      return Math.round(number * Math.pow(10,digits)) / Math.pow(10,digits);
    },
    $mapsUrl(standort) {
      let url = 'https://www.google.de/maps/place/';
      if (standort.strasse) url += standort.strasse+' ';
      if (standort.plz) url += standort.plz+' ';
      if (standort.ort) url += standort.ort+' ';
      return url;
    },
    $gruppenleitung(mitarbeiter_id) {
      return this.$model.gruppenleitung_mitarbeiter_ids.includes(mitarbeiter_id);
    },
    $optionenVerordnungstyp(fachbereich = 0) {
      let verordnungstypenErlaubt = this.$getConfig('verordnung.verordnungstypenErlaubt');
      if (!verordnungstypenErlaubt) return {0: 'Keine Verordnungstypen erlaubt'};
      console.log(verordnungstypenErlaubt);

      let verordnungstypen = {};
      if (fachbereich == 0) {
        if (verordnungstypenErlaubt.includes(0)) verordnungstypen[0] = 'Heilmittelverordnung 13';
        if (verordnungstypenErlaubt.includes(1)) verordnungstypen[1] = 'Zahnärztliche Heilmittelverordnung';
        if (verordnungstypenErlaubt.includes(3)) verordnungstypen[3] = 'Privatverordnung';
        if (verordnungstypenErlaubt.includes(5)) verordnungstypen[5] = 'Frühförderung';
      }
      else if (fachbereich == 1) {
        if (verordnungstypenErlaubt.includes(0)) verordnungstypen[0] = 'Heilmittelverordnung 13';
        if (verordnungstypenErlaubt.includes(1)) verordnungstypen[2] = 'Blanko Verordnung';
        if (verordnungstypenErlaubt.includes(3)) verordnungstypen[3] = 'Privatverordnung';
        if (verordnungstypenErlaubt.includes(3)) verordnungstypen[4] = 'BG Verordnung';
      }
      else verordnungstypen = {0: 'Weitere Fachbereiche können wir noch nicht!'};
      return verordnungstypen;
    },
    $getConfig(key, fallback = '') {
      let getFromStore = this.$store.getters['config'](key);
      if (!getFromStore) return fallback;
      return getFromStore;
    }
  },
  computed: {
    $debug() {
      return this.$store.getters['debugMode'];
    },
    $fachbereiche() {
      return {0: 'Logopädie', 1: 'Ergotherapie'};
    },
    $fachbereichClass() {
      return {0: 'logo', 1: 'ergo'};
    },
    $appVersion() {
      return process.env.APP_VERSION+' #'+process.env.buildnumber;
    },
    $dev() {
      if (process.env.STAGE == 'dev') return true;
      return false;
    },
    $staging() {
      if (process.env.STAGE == 'staging') return true;
      return false;
    },
    $production() {
      if (process.env.STAGE == 'production') return true;
      return false;
    },
    $fachgebieteArzt: function() {
      return _.sortBy([
        {id: 1, name: 'Allgemeinmedizin'},
        {id: 2, name: 'Hals-Nasen-Ohren-Heilkunde'},
        {id: 3, name: 'Innere Medizin '},
        {id: 4, name: 'Kieferorthopädie'},
        {id: 5, name: 'Kinder- und Jugendmedizin (Pädiatrie)'},
        {id: 6, name: 'Mund-Kiefer-Gesichts-Chirurgie'},
        {id: 7, name: 'Neurologie'},
        {id: 8, name: 'Pädaudiologie'},
        {id: 9, name: 'Phoniatrie'},
        {id: 10, name: 'Zahnmedizin'},
        {id: 11, name: 'Anästhesiologie'},
        {id: 12, name: 'Anatomie'},
        {id: 13, name: 'Arbeitsmedizin'},
        {id: 14, name: 'Augenheilkunde'},
        {id: 15, name: 'Biochemie'},
        {id: 16, name: 'Chirurgie'},
        {id: 17, name: 'Frauenheilkunde und Geburtshilfe'},
        {id: 18, name: 'Haut- und Geschlechtskrankheiten'},
        {id: 19, name: 'Humangenetik'},
        {id: 20, name: 'Hygiene und Umweltmedizin'},
        {id: 21, name: 'Kinder- und Jugendpsychiatrie und -psychotherapie'},
        {id: 22, name: 'Laboratoriumsmedizin'},
        {id: 23, name: 'Mikrobiologie, Virologie und Infektionsepidemiologie'},
        {id: 24, name: 'Mund-Kiefer-Gesichtschirurgie'},
        {id: 25, name: 'Neurochirurgie'},
        {id: 26, name: 'Nuklearmedizin'},
        {id: 27, name: 'Öffentliches Gesundheitswesen'},
        {id: 28, name: 'Pathologie'},
        {id: 29, name: 'Pharmakologie'},
        {id: 30, name: 'Physikalische und Rehabilitative Medizin'},
        {id: 31, name: 'Physiologie'},
        {id: 32, name: 'Psychiatrie und Psychotherapie'},
        {id: 33, name: 'Psychosomatische Medizin und Psychotherapie'},
        {id: 34, name: 'Radiologie'},
        {id: 35, name: 'Rechtsmedizin'},
        {id: 36, name: 'Strahlentherapie'},
        {id: 37, name: 'Transfusionsmedizin'},
        {id: 38, name: 'Urologie'},
      ], 'name');
    },
    $verordnungStatus() {
      return {
        'unvollstaendig': 'Unvollständig',
        'ungeprueft': 'Ungeprüft',
        'aenderung_erforderlich': 'Änderung erforderlich',
        'aenderung_laeuft': 'Änderung läuft',
        'in_therapie': 'In Therapie',
        'in_abrechnung': 'In Abrechnung',
        'abgerechnet': 'Abgerechnet',
      };
    },
  },

}
Vue.mixin(helpers);
export default helpers;

/**
 * Alle Nicht Gesundheitsportal spezifischen Helper wurden ausgegliedert in das p1ng Quickstart Package und können/dürfen da ergänzt werden.
 */
